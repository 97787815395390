import React from "react";

const CartItemsCheckout = ({ product, isSuccess }) => {
	const { name, image, price, quantity } = product;

	return (
		<div
			className={`flex flex-row rounded-lg w-full lg:border-[1px] lg:border-neutral-200 mb-1 px-2 lg:px-0`}>
			<div className="mr-5">
				<img
					className="w-[100px] h-[50px] object-cover rounded-l-lg shadow-lg"
					src={image}
					alt={name}
				/>
			</div>
			<div className="flex flex-row justify-between items-center w-[100%]">
				<div className="text-sm font-light text-white">
					<div className="font-bold">
						<h4>{name}</h4>
					</div>
					<p>{`Price: $${price * quantity}`}</p>
				</div>
				<div className="flex flex-row items-center justify-center h-full w-[15%] bg-neutral-200 py-3 px-2 rounded-r-md">
					<div>
						<span className="text-sm">{quantity}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CartItemsCheckout;
