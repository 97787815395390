import React from "react";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { FaOpencart } from "react-icons/fa";
import {
	MdOutlineKeyboardArrowDown,
	MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import CartItemsCheckout from "./CartItemsCheckout";

const OrderSum = ({
	sales,
	isBorderBottom,
	isBorder,
	showLoad,
	cartItems,
	discount,
	handleDisount,
	error,
	handleApply,
	generateCode,
	code,
	discountPrice,
	total,
	isSuccess,
	shipCost,
	tax,
	discountedMargin,
	notShippingText,
}) => {
	const [showSum, setShowSum] = React.useState(false);
	const [showInfo, setShowInfo] = React.useState(false);
	return (
		<div className="relative">
			<div
				className={`${isSuccess ? "" : "block lg:hidden"} text-[#bba371] ${
					showSum ? "mb-5" : "mb-0"
				} pt-5 ${isBorderBottom && "border-b-[1px]"} ${
					isBorder ? " border-t-[1px] border-neutral-800" : ""
				} flex mx-auto justify-center`}>
				<div className={`flex items-center px-5 text-xs`}>
					<FaOpencart className="mr-2" size={25} />
					<span>
						{showSum ? "Hide" : "Show"} order summary{" "}
						{sales ? (
							<span className="font-light text-white">/ apply discount</span>
						) : (
							<></>
						)}{" "}
					</span>
					{showSum ? (
						<MdOutlineKeyboardArrowUp
							size={25}
							onClick={() => setShowSum(false)}
							className="cursor-pointer"
						/>
					) : (
						<MdOutlineKeyboardArrowDown
							size={25}
							onClick={() => setShowSum(true)}
							className="cursor-pointer"
						/>
					)}
				</div>
			</div>
			<div
				className={`${
					showSum ? `opacity-1 py-5` : "h-0 opacity-0"
				} ease-in duration-300 ${
					isSuccess
						? "lg:px-[220px] xl:px-[320px] 2xl:px-[520px]"
						: "lg:hidden block lg:w-[30%] md:px-10"
				} w-full p-1 md:py-5 bg-neutral-900 `}>
				{cartItems.map((item) => (
					<CartItemsCheckout
						product={item}
						key={item.id}
						isSuccess={isSuccess}
					/>
				))}
				{sales > 0 && (
					<>
						<div className="flex items-center w-full py-5">
							<input
								name="discount"
								type="text"
								value={discount}
								onChange={handleDisount}
								placeholder="Discount code"
								className={`${
									error && discount === ""
										? "border-red-700 border-[1px]"
										: "border-neutral-400 border-[1px]"
								} text-sm font-light rounded-md w-[70%] py-2 placeholder:text-xs `}
							/>
							<button
								className="w-[30%] bg-neutral-900 text-white ml-2 py-[11px] text-xs rounded-md"
								onClick={handleApply}>
								Apply
							</button>
						</div>
						{error && (
							<div className="pt-2 pb-5 ">
								<p className="text-xs text-center text-red-700">
									<span className="text-sm font-bold">
										Discount Code Not Valid!
									</span>
									<br />
									<span className="font-light tracking-wider text-neutral-700">
										Please generate a new code
									</span>
								</p>
								<div className="text-[10px] w-full mx-auto flex items-center justify-center mt-3">
									<button
										disabled={code}
										onClick={generateCode}
										className="p-2 text-white duration-300 ease-in rounded-md bg-neutral-900 hover:md:bg-white hover:md:text-neutral-900">
										Generate code
									</button>
								</div>
								<div>
									<p className="w-full mt-4 text-xs font-bold text-center text-white yellow">
										{code}
									</p>
								</div>
							</div>
						)}
					</>
				)}
				<div className="relative">
					<div className="border-b-[1px] text-[12px] font-light border-[#ceaf6c] py-5 text-white px-3">
						<div className="flex items-center justify-between text-sm">
							<span>Subtotal</span>
							{showLoad ? (
								<div className="circular-loader-small"></div>
							) : (
								<span>${discountPrice ? discountPrice : total}</span>
							)}
						</div>
						{discountedMargin ? (
							<div className="flex items-center justify-between">
								<span>Discount</span>
								<span>${discountedMargin}</span>
							</div>
						) : (
							<></>
						)}
						{tax && (
							<div className="flex items-center justify-between">
								<span>Tax</span>
								<span className="text-[16px]">${tax}</span>
							</div>
						)}
						<div className="flex items-center justify-between ">
							<div className="relative flex items-center">
								<span>Shipping</span>
								<BsFillQuestionCircleFill
									size={15}
									onMouseOver={() => setShowInfo(true)}
									onMouseLeave={() => setShowInfo(false)}
									className="mx-1 text-neutral-500 hover:cursor-pointer"
								/>
								{showInfo && (
									<div className="absolute w-[170px] bg-[rgba(0,0,0,0.85)] p-2 rounded-md rounded-bl-none text-white text-center top-[-50px] left-[74px] flex items-top">
										<span className="text-left">
											Review shipping policy in help tab
										</span>
										{/* <BsFillHandIndexFill size={30} /> */}
									</div>
								)}
							</div>
							<span>{shipCost ? `$${shipCost}` : notShippingText}</span>
						</div>
					</div>
					<div
						className={`flex items-center justify-between text-sm pt-5 px-3 text-white ${
							!showSum && "absolute bottom-[500px]"
						}`}>
						<span className="text-[16px]">Total</span>
						<div className="flex items-center">
							<span className="text-[12px] mr-2 ">USD</span>
							{showLoad ? (
								<div className="circular-loader-small"></div>
							) : (
								<span className="text-[26px]">
									$
									{discountPrice
										? shipCost && tax
											? tax + shipCost + discountPrice
											: tax + discountPrice
										: shipCost && tax
										? tax + shipCost + total
										: (total + tax).toFixed(2)}
								</span>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderSum;
