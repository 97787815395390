export const SUPER_USER1 = "tisijola7@gmail.com";
export const SHIPPING_COST = {
	US: 7.99,
};
export const CANADA = "canada";
export const USERS = "users";
export const FREE_SHIPPING_AMOUNT = 200;
export const CURRENCY = "cad";
export const TAX_PERCENT = 0.13;
export const REACT_APP_CLOUD_NAME = "dhdccvkyt";
export const WHATSAPPBASEURL = "https://wa.me/";

// Back end url
export const HTTPS_LINK = "http://localhost:5000";
// export const HTTPS_LINK = 'https://www.model-est.com'

export const AUTHORIZED_ID = {
	id_one: "pvginternationals@gmail.com",
	id_two: "tisijola7@gmail.com",
};
