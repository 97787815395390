function Heading({
	children,
	isBold,
	isBold2,
	size1,
	size2,
	size3,
	size4,
	size5,
	size6,
	isLead,
	isWhite,
	isBlack,
	isGrey,
	isLight,
	isUndeline,
}) {
	return (
		<h1
			className={`capitalize ${isLight && "font-light"} ${
				isWhite && "text-white"
			} ${isBlack && "text-black"} ${isBold && "font-bold"} ${
				isBold2 && "font-normal"
			} ${size1 && "text-xs"} ${isUndeline && "text-[10px]"} ${
				size2 && "text-sm"
			} ${size3 && "text-[18px]"} ${size4 && "text-lg"} ${size5 && "text-xl"} ${
				size6 && "text-2xl"
			} ${isLead && "leading-8"} ${isGrey && "text-neutral-500"} ${
				isUndeline && "underline"
			} 'text-neutral-700'`}>
			{children}
		</h1>
	);
}

export default Heading;
