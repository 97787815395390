const colorChart = [
	{
		name: "1",
		image:
			"https://www.wigtypes.com/cdn/shop/files/1-jet-black.png?v=15180208479189218357",
	},

	{
		name: "1B",
		image:
			"https://www.wigtypes.com/cdn/shop/files/1b-off-black.png?v=9907565608895631103",
	},
	{
		name: "2",
		image:
			"https://www.wigtypes.com/cdn/shop/files/2-dark-brown.png?v=8475575419785905166",
	},
	{
		name: "4",
		image:
			"https://www.wigtypes.com/cdn/shop/files/4-light-brown.png?v=9622898140612842631",
	},
	{
		name: "99J",
		image:
			"https://www.wigtypes.com/cdn/shop/files/99j.png?v=8398134168422550410",
	},
	{
		name: "2T1B/27",
		image:
			"https://www.wigtypes.com/cdn/shop/files/2t1b-27.png?v=6866673366250438024",
	},
	{
		name: "2T1B/30",
		image:
			"https://www.wigtypes.com/cdn/shop/files/2t1b-30.png?v=2267914457600389708",
	},
	{
		name: "2T1B/425",
		image:
			"https://www.wigtypes.com/cdn/shop/files/2t1b-425.png?v=8855977147109483972",
	},
	{
		name: "3T1B/GLD",
		image:
			"https://cdn.shopify.com/s/files/1/2262/0005/t/44/a…option_62249_-vivicafox-3t1b-gld.jpg?v=1657823457",
	},
	{
		name: "TP4/30",
		image:
			"https://www.wigtypes.com/cdn/shop/files/tp4-30.png?v=6542567735428359338",
	},
	{
		name: "TP4/27",
		image:
			"https://www.wigtypes.com/cdn/shop/files/tp4-27.png?v=9518166065071337560",
	},
	{
		name: "TT30",
		image:
			"https://www.wigtypes.com/cdn/shop/files/tt30.png?v=18032583000615688373",
	},
	{
		name: "TT33",
		image:
			"https://www.wigtypes.com/cdn/shop/files/tt33.png?v=9023498558680432903",
	},
	{
		name: "TT27",
		image:
			"https://www.wigtypes.com/cdn/shop/files/tt27.png?v=105674773567322960",
	},
	{
		name: "TP1B/27",
		image:
			"https://www.wigtypes.com/cdn/shop/files/tp1b-27.png?v=8500084063870482965",
	},
	{
		name: "TP1B/30",
		image:
			"https://www.wigtypes.com/cdn/shop/files/tp1b-30.png?v=9969405239833184110",
	},
	{
		name: "TP1B/33",
		image:
			"https://www.wigtypes.com/cdn/shop/files/tp1b-33.png?v=4812311356211465648",
	},
];

export { colorChart };
