import React from "react";
import StripeCheckout from "./checkout/stripe-checkout";

const Total = React.memo(({ itemCount, total }) => {
	return (
		<div className="p-5 rounded-lg shadow-lg md:pt-0">
			{/* <div className="py-3 px-5 text-slate-600 max-w-[100%] lg:max-w-[70%] mx-auto flex flex-row justify-between"></div> */}
			<StripeCheckout total={total} itemCount={itemCount} />
		</div>
	);
});

export default Total;
