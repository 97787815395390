function Button(props) {
	return (
		<button
			disabled={props.isDisabled}
			onClick={props.handleFunc}
			type={props.type}
			className={`bg-black text-xs text-white py-2 mt-5 font-light hover:bg-gray-600 ease-in duration-300 shadow-xl shadow-neutral-700 ${
				props.type === "large" ? "px-5 max-w-[120px]" : "px-3 max-w-[150px]"
			}`}>
			{props.children}
		</button>
	);
}
export default Button;
