import React from "react";
import axios from "axios";
import InventoryForm from "./InventoryForm";
import { uploadImage } from "../utils/uploadFileFunc";

const Inventory = React.memo(() => {
	const [isTrueSales, setIsTrueSales] = React.useState(false);
	const [soldout, setSoldout] = React.useState(false);
	const [postError, setPostError] = React.useState("");
	const [formData, setFormData] = React.useState({
		title: "",
		type: "",
		color: "",
		price: "",
		length: "",
		percent: "",
		description: "",
	});
	const [imageFile, setImageFile] = React.useState("");
	const handleformDataChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const toggleSales = () => {
		setIsTrueSales(!isTrueSales);
	};
	const toggleSoldout = () => {
		setSoldout(!soldout);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const product = {
				name: formData.title,
				type: formData.type,
				color: formData.color,
				price: formData.price,
				length: formData.length,
				percent: formData.percent,
				description: formData.description,
				sales: isTrueSales,
				soldout: soldout,
				images: imageFile,
			};
			await axios.post("/api/v1/products", product);
		} catch (error) {
			setPostError(error.message);
			console.log(error);
		}

		setFormData({
			title: "",
			type: "",
			color: "",
			price: "",
			length: "",
			percent: "",
			description: "",
		});
		setIsTrueSales(false);
	};

	const inputTypes = [
		{
			id: "1",
			name: "title",
			type: "text",
			value: formData.title,
			placeholder: "Product Name...",
		},
		{
			id: "2",
			name: "type",
			type: "text",
			value: formData.type,
			placeholder: "Product Type...",
		},
		{
			id: "3",
			name: "color",
			type: "text",
			value: formData.color,
			placeholder: "Product Color...",
		},
		{
			id: "4",
			name: "length",
			type: "number",
			value: formData.length,
			placeholder: "Length...",
		},
		{
			id: "5",
			name: "price",
			type: "number",
			value: formData.price,
			placeholder: "Price CA($)...",
		},
		{
			id: "6",
			name: "percent",
			type: "number",
			value: formData.percent,
			placeholder: "Sales %...",
		},
	];

	return (
		<InventoryForm
			handleSubmit={handleSubmit}
			inputTypes={inputTypes}
			handleformDataChange={handleformDataChange}
			formData={formData}
			isTrueSales={isTrueSales}
			soldout={soldout}
			toggleSales={toggleSales}
			toggleSoldout={toggleSoldout}
			postError={postError}
			buttonText="Add to Inventory"
			uploadFile={(e) => uploadImage(e, setImageFile)}
		/>
	);
});

export default Inventory;
