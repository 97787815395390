import Footer from "../Footer";
import Nav from "../Nav";

function Layout({ children }) {
	return (
		<div className="relative w-full max-w-max-width my-[10px] bg-gradient-to-r from-neutral-200 via-neutral-50 to-neutral-200 mx-auto justify-center border-2 border-neutral-200 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] rounded-[30px] lg:p-6">
			<div className="px-2 pt-6 pb-2 xs:px-6 lg:p-0">
				<Nav />
			</div>
			<div className="lg:p-0 my-[10px]">{children}</div>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Layout;
