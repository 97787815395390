import React from "react";
import {
	MdDoubleArrow,
	MdKeyboardArrowLeft,
	MdKeyboardArrowRight,
} from "react-icons/md";

const Pagination = React.memo(
	({ totalNumberOfChunked, setChunkIndex, chunkIndex, chunkCards }) => {
		const paginate = [];
		// eslint-disable-next-line array-callback-return
		chunkCards.map((item, idx) => {
			const obj = {
				key: idx,
			};
			paginate.push(obj);
		});
		const nextPage = () => {
			if (chunkIndex <= totalNumberOfChunked) {
				setChunkIndex(chunkIndex + 1);
			}
		};
		const prevPage = () => {
			if (chunkIndex < totalNumberOfChunked) {
				setChunkIndex(chunkIndex - 1);
			}
		};

		function firstPage() {
			setChunkIndex(0);
		}
		function lastPage() {
			setChunkIndex(paginate.length - 1);
		}

		return (
			<div className="flex items-center justify-center w-full mx-auto mt-10 mb-5">
				<div className="flex items-center ">
					<button
						onClick={firstPage}
						className="p-[2px] border-[1px] border-cyan-900 rounded-md text-cyan-900 m-1 rotate-180 shadow-md">
						<MdDoubleArrow size={20} />
					</button>
					<button
						disabled={chunkIndex === 0 && true}
						onClick={prevPage}
						className="p-[1px] border-[1px] border-cyan-900 rounded-md text-cyan-900 m-2 shadow-md">
						<MdKeyboardArrowLeft size={30} />
					</button>
					<div className="flex items-center justify-center text-sm font-light text-cyan-800">
						{paginate.length < 5 && (
							<div div className="flex items-center justify-center">
								{paginate?.map((item, idx) => (
									<div key={idx} className="p-1">
										<span
											className={`${
												chunkIndex === item?.key &&
												"py-2 px-3 shadow-md rounded-md text-cyan-900 bg-neutral-300 border border-cyan-100 font-bold text-[18px]"
											} `}>
											{item?.key + 1}
										</span>
									</div>
								))}
							</div>
						)}
						{paginate.length === 5 && (
							<>
								<div className="flex items-center justify-center">
									{paginate.slice(0, 3)?.map((item) => (
										<div className="p-1">
											<span
												className={`${
													chunkIndex === item?.key &&
													"py-2 px-3 shadow-md rounded-md text-cyan-900 bg-neutral-300 border border-cyan-100 font-bold text-[18px]"
												}`}>
												{item?.key + 1}
											</span>
										</div>
									))}
								</div>
								<span className="px-2 mb-2">...</span>
								<div className="flex items-center justify-center">
									{paginate.slice(4, paginate.length)?.map((item) => (
										<div className="p-1">
											<span
												className={`${
													chunkIndex === item?.key &&
													"py-2 px-3 shadow-md rounded-md text-cyan-900 bg-neutral-300 border border-cyan-100 font-bold text-[18px]"
												}`}>
												{item?.key + 1}
											</span>
										</div>
									))}
								</div>
							</>
						)}
						{paginate.length > 5 && (
							<>
								<div className="flex items-center justify-center">
									{paginate.slice(0, 3)?.map((item) => (
										<div className="p-1">
											<span
												className={`${
													chunkIndex === item?.key &&
													"py-2 px-3 shadow-md rounded-md text-cyan-900 bg-neutral-300 border border-cyan-100 font-bold text-[18px]"
												}`}>
												{item?.key + 1}
											</span>
										</div>
									))}
								</div>
								<span className="px-2 mb-2">...</span>
								<div className="flex items-center justify-center">
									{paginate.slice(5, paginate.length)?.map((item) => (
										<div className="p-1">
											<span
												className={`${
													chunkIndex === item?.key &&
													"py-2 px-3 shadow-md rounded-md text-cyan-900 bg-neutral-300 border border-cyan-100 font-bold text-[18px]"
												}`}>
												{item?.key + 1}
											</span>
										</div>
									))}
								</div>
							</>
						)}
					</div>
					<button
						disabled={
							Number.isInteger(totalNumberOfChunked)
								? chunkIndex === Math.floor(totalNumberOfChunked) - 1 && true
								: chunkIndex === Math.floor(totalNumberOfChunked) && true
						}
						onClick={nextPage}
						className="p-[2px] border-[1px] border-cyan-900 rounded-md text-cyan-900 m-2 shadow-md">
						<MdKeyboardArrowRight size={30} />
					</button>
					<button
						onClick={lastPage}
						className="p-[2px] border-[1px] border-cyan-900 rounded-md text-cyan-900 m-1 shadow-md">
						<MdDoubleArrow size={20} />
					</button>
				</div>
			</div>
		);
	}
);

export default Pagination;
