import React from "react";
import { SiWhatsapp } from "react-icons/si";
import { WHATSAPPBASEURL } from "../../constant";

const CtaButton = (props) => {
	return (
		<a
			href={`${WHATSAPPBASEURL}${process.env.REACT_APP_WHATSAPPNUMBER}`}
			target="_blank"
			rel="noopener noreferrer"
			className={`flex flex-row mx-auto items-center justify-center text-white bg-green-700 ${
				props?.text ? "rounded-lg" : "h-full w-[55px]"
			} p-2 font-light text-sm `}>
			{props?.text && <span className="mr-2">{props?.text}</span>}
			<SiWhatsapp size={props?.isIcon ? 25 : 18} />
		</a>
	);
};

export default CtaButton;
